var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-card',[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('v-select',{staticClass:"mt-1 mb-2",attrs:{"dir":_vm.dir,"placeholder":"Select Course","label":"name","options":_vm.options,"loading":_vm.isLoading,"transition":"fade","disabled":""},on:{"search":_vm.debounceInput},model:{value:(_vm.selectedCourse),callback:function ($$v) {_vm.selectedCourse=$$v},expression:"selectedCourse"}})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('user-roles-select',{attrs:{"hide-label":"","list-type":"participant","read-only":""},on:{"update":_vm.updateSelectedUserRole},model:{value:(_vm.selectedUserRole),callback:function ($$v) {_vm.selectedUserRole=$$v},expression:"selectedUserRole"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"mt-1"},[_vm._v("Available")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-when","name":"When"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"show-when","label":"name","placeholder":"When","options":_vm.eventTriggersDisplay,"state":_vm.getValidationState(
                    validationContext
                  )},model:{value:(_vm.happenWhen),callback:function ($$v) {_vm.happenWhen=$$v},expression:"happenWhen"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12","lg":"8"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-actionable-event","name":"Event"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('aom-events-select',{attrs:{"id":"show-actionable-event","options":_vm.eventsDisplay,"state":_vm.getValidationState(
                    validationContext
                  )},model:{value:(_vm.actionableEvent),callback:function ($$v) {_vm.actionableEvent=$$v},expression:"actionableEvent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(_vm.showCustomDateField)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('aom-date-picker',{attrs:{"min":_vm.minCustomDate},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}})],1)],1):_vm._e()],1),(_vm.showPeriodField)?_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"vid":"time","rules":{ required: true },"name":"Period"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"time","label":"#","placeholder":"Period","options":_vm.periodList,"state":_vm.getValidationState(
                    validationContext
                  )},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e(),(_vm.showDurationField)?_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"vid":"duration","rules":{ required: true },"name":"Duration"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"duration","label":"name","placeholder":"Duration","options":_vm.durationUnitsDisplay,"state":_vm.getValidationState(
                    validationContext
                  )},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('action-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.onUpdate)}}},[(_vm.isSubmitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isSubmitting)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Submit")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }