<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid, handleSubmit }"
  >
    <b-card>
      <b-form>
        <!-- Course -->
        <b-row class="mt-1">
          <b-col md="12">
            <v-select
              v-model="selectedCourse"
              class="mt-1 mb-2"
              :dir="dir"
              placeholder="Select Course"
              label="name"
              :options="options"
              :loading="isLoading"
              transition="fade"
              disabled
              @search="debounceInput"
            />
          </b-col>
        </b-row>

        <!-- User role -->
        <b-row class="mt-1">
          <b-col md="12">
            <user-roles-select
              v-model="selectedUserRole"
              hide-label
              list-type="participant"
              read-only
              @update="updateSelectedUserRole"
            />
          </b-col>
        </b-row>

        <!-- Available -->
        <b-row class="mt-2">
          <b-col md="3">
            <label class="mt-1">Available</label>
          </b-col>
        </b-row>
        <b-row>
          <!-- When -->
          <b-col
            md="12"
            lg="4"
          >
            <validation-provider
              v-slot="validationContext"
              rules="required"
              vid="show-when"
              name="When"
            >
              <b-form-group label-for="show">
                <v-select
                  id="show-when"
                  v-model="happenWhen"
                  label="name"
                  placeholder="When"
                  :options="eventTriggersDisplay"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Event -->
          <b-col
            md="12"
            lg="8"
          >
            <validation-provider
              v-slot="validationContext"
              rules="required"
              vid="show-actionable-event"
              name="Event"
            >
              <b-form-group label-for="show">
                <aom-events-select
                  id="show-actionable-event"
                  v-model="actionableEvent"
                  :options="eventsDisplay"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Custom date -->
            <b-row v-if="showCustomDateField">
              <b-col md="12">
                <aom-date-picker
                  v-model="scheduledDate"
                  :min="minCustomDate"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Period -->
          <b-col
            v-if="showPeriodField"
            md="12"
            lg="6"
          >
            <validation-provider
              v-slot="validationContext"
              vid="time"
              :rules="{ required: true }"
              name="Period"
            >
              <b-form-group label-for="show">
                <v-select
                  id="time"
                  v-model="period"
                  label="#"
                  placeholder="Period"
                  :options="periodList"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Duration -->
          <b-col
            v-if="showDurationField"
            md="12"
            lg="6"
          >
            <validation-provider
              v-slot="validationContext"
              vid="duration"
              :rules="{ required: true }"
              name="Duration"
            >
              <b-form-group label-for="show">
                <v-select
                  id="duration"
                  v-model="duration"
                  label="name"
                  placeholder="Duration"
                  :options="durationUnitsDisplay"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <!-- submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <action-button
            variant="primary"
            :disabled="invalid"
            @click="handleSubmit(onUpdate)"
          >
            <b-spinner
              v-if="isSubmitting"
              small
            />
            <span v-if="isSubmitting"> Updating...</span>
            <span v-else>Submit</span>
          </action-button>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  VBModal,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import Ripple from "vue-ripple-directive";
import trainingsService from "@/services/trainingsService";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import {
  actions,
  eventTriggers,
  eventTriggersDisplay,
  actionableEvent as defaultEvents,
  durationUnitsDisplay
} from "@/models";
//eslint-disable-next-line
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState,
  convertStringToDateIgnoreTimezone
} from "@/libs/utils";
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
    BRow,
    BCol,
    BCard,
    BForm,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,
    UserRolesSelect,
    AomEventsSelect,
    AomDatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isProgramTraining: {
      type: Boolean
    },
    training: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      keyword: null,
      selectedCourse: null,
      options: [],
      selectedUserRole: [],
      dir: "ltr",
      isLoading: false,
      isSubmitting: false,
      trainingId: 0,
      eventTriggersDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
    };
  },
  watch: {
    training: {
      handler(n) {
        if (n) {
          this.setTraining(n);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // Only load scorm courses for library
    if (!this.isProgramTraining) {
      this.loadItems();
    }
  },
  methods: {
    getScheduledActions() {
      const scheduledAction = {
        action_id: actions.SHOW
      };
      let newPeriod = 0;
      let newDuration = this.duration?.id;
      // Convert relative_value
      if (this.happenWhen) {
        switch (this.happenWhen.id) {
          case eventTriggers.BEFORE:
            newPeriod = -this.period;
            break;
          case eventTriggers.ON:
            newPeriod = 0;
            newDuration = undefined;
            break;
          case eventTriggers.AFTER:
            newPeriod = this.period;
            break;
        }
      }
      // Assign values to scheduledAction
      // Custom date
      if (this.actionableEvent.id === defaultEvents.CUSTOM_DATE && this.scheduledDate) {
        scheduledAction.absolute_date = this.scheduledDate;
      } else {
        scheduledAction.actionable_event_id = this.actionableEvent.id;
      }
      if (newPeriod) {
        scheduledAction.relative_value = newPeriod;
      }
      if (newDuration) {
        scheduledAction.relative_unit_id = newDuration;
      }
      return [scheduledAction];
    },
    async onUpdate() {
      this.isSubmitting = true;
      try {
        if (this.isProgramTraining) {
          await trainingsService.updateProgramTraining(
            this.$route.params.id,
            this.trainingId,
            {
              course_id: this.selectedCourse.id,
              course_name: this.selectedCourse.name,
              roles: this.selectedUserRole,
              scheduled_actions: this.getScheduledActions(),
            }
          );
        } else {
          await trainingsService.updateLibraryTraining(
            this.trainingId,
            {
              course_id: this.selectedCourse.id,
              course_name: this.selectedCourse.name,
              roles: this.selectedUserRole,
              scheduled_actions: this.getScheduledActions(),
            }
          );
        }

        this.$emit("trainingUpdated");
        this.$toast(makeSuccessToast("Training was successfully updated"));
        this.$root.$emit("bv::toggle::collapse", "edit-training-right");
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.isLoading = true;
      try {
        const response = await trainingsService.getLibraryScormCourses({
          columnFilters: this.keyword
            ? [
                {
                  field: "full_name",
                  value: this.keyword,
                },
              ]
            : [],
        });
        this.options = response.data.items.map(course => ({
          id: course.id,
          name: course.title,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    updateSelectedUserRole(role) {
      this.selectedUserRole = role;
    },
    clearForm() {
      this.selectedCourse = null;
      this.selectedUserRole = [];
    },
    setTraining(training) {
      this.trainingId = training.id;
      this.selectedCourse = {id: training.course_id, name: training.course_name};
      this.selectedUserRole = training.roles?.map(r => ({id: r.id, name: r.name}));

      // Parse scheduled action
      if (training.scheduled_action) {
        const scheduledAction = training.scheduled_action;

        if (!scheduledAction.relative_value) {
          this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
        } else {
          if (scheduledAction.relative_value < 0) {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.BEFORE);
          } else {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.AFTER);
          }
        }

        this.$nextTick(() => {
          this.actionableEvent = scheduledAction.absolute_date
              ? { id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }
              : this.eventsDisplay.filter(a => a.id === scheduledAction.actionable_event_id).shift();
          this.period = Math.abs(scheduledAction.relative_value);
          this.duration = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id)[0];
          this.scheduledDate = scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined;
        });
      }
    },
    cancel() {
      this.clearForm();
      this.$root.$emit("bv::toggle::collapse", "edit-training-right");
    }
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      minCustomDate
    } = useActionableEvent(props.isProgramTraining);

    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      getValidationState,
      minCustomDate
    };
  },
};
</script>
