<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    scrollable
    no-enforce-focus
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ modalTitle }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>
  
    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <action-button
          variant="primary"
          right
          @click="sendEmail"
        >
          <feather-icon
            icon="SendIcon"
            class="mr-1 cursor-pointer"
          />
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span>{{ submitButtonText }}</span>
        </action-button>
      </div>
      <div>
        <action-button
          v-if="communication && communication.is_schedulable && !isProgramTemplateComms"
          class="mr-2"
          variant="primary"
          right
          :disabled="!canSendNow || isScheduledSending"
          @click="sendNow"
        >
          <feather-icon
            icon="SendIcon"
            class="mr-1 cursor-pointer"
          />
          <b-spinner
            v-if="isScheduledSending"
            small
          />
          <span>Send now</span>
        </action-button>
        <a
          href="javascript:void(0)"
          @click="testSendEmail"
        >
          {{ sendTestText }}
        </a>
      </div>
    </template>
  
    <!-- Modal: Body -->
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(sendEmail)">
        <template v-if="type === emailComposeTypes.SCHEDULED">
          <div class="compose-mail-form-field justify-content-start">
            <label for="name-internal">Name (Internal): </label>
            <validation-provider
              v-slot="validationContext"
              ref="emailName"
              :rules="isAdminPortal ? 'required' : ''"
              name="Name (Internal)"
              class="w-100"
            >
              <b-form-input
                id="name-internal"
                v-model="composeData.name"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div
            class="compose-mail-form-field justify-content-start"
          >
            <label
              for="email-to"
              class="form-label"
            >
              When to Send:
            </label>
            <b-row class="w-100">
              <!-- When -->
              <b-col md="3">
                <validation-provider
                  v-slot="validationContext"
                  rules="required"
                  vid="show-when"
                  name="When"
                >
                  <b-form-group label-for="show">
                    <v-select
                      id="show-when"
                      v-model="happenWhen"
                      label="name"
                      placeholder="When"
                      :options="eventTriggersDisplay"
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Event -->
              <b-col md="5">
                <validation-provider
                  v-slot="validationContext"
                  rules="required"
                  vid="show-actionable-event"
                  name="Event"
                >
                  <b-form-group label-for="show">
                    <aom-events-select
                      id="show-actionable-event"
                      v-model="actionableEvent"
                      :options="eventsDisplayComputed"
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Custom date -->
                <b-row v-if="showCustomDateField">
                  <b-col md="12">
                   <validation-provider
                      v-slot="validationContext"
                      rules="required"
                      vid="scheduledDate"
                      name="Date"
                    >
                      <b-form-group label-for="show">
                        <aom-date-picker
                          :value-as-date="true"
                          v-model="scheduledDate"
                          :min="minCustomDate"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Period -->
              <b-col
                v-if="showPeriodField"
                sm="2"
              >
                <validation-provider
                  v-slot="validationContext"
                  vid="time"
                  :rules="{ required: true }"
                  name="Period"
                >
                  <v-select
                    id="time"
                    v-model="period"
                    label="#"
                    placeholder="Period"
                    :options="periodList"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <!-- Duration -->
              <b-col
                v-if="showDurationField"
                sm="2"
              >
                <b-form-group label-for="show">
                  <validation-provider
                    v-slot="validationContext"
                    vid="duration"
                    :rules="{ required: true }"
                    name="Duration"
                  >
                    <v-select
                      id="duration"
                      v-model="duration"
                      label="name"
                      placeholder="Duration"
                      :options="durationUnitsDisplay"
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    />
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>  
              </b-col>
            </b-row>
          </div>

          <!-- Field: enable / disable -->
          <div
            v-if="isProgramCommunications"
            class="compose-mail-form-field justify-content-start"
          >
            <label
              for="email-to"
              class="form-label"
            >
              Enable / Disable:
            </label>
            <b-form-checkbox
              v-model="composeData.is_enabled"
              name="is-rtl"
              class="my-50"
              switch
              inline
            />
          </div>
        </template>

        <!-- Field: To -->
        <div
          v-if="!isLibraryComms"
          class="compose-mail-form-field justify-content-start"
        >
          <label
            for="email-to"
            class="form-label"
          >
            To:
          </label>
          <template v-if="type === emailComposeTypes.MESSAGE">
            <feather-icon
              class="ml-50"
              icon="UsersIcon"
              size="20"
            />
            <validation-provider
              v-if="composeData && composeData.group"
              v-slot="validationContext"
              vid="email-to-group"
              :rules="{ required: isRequired }"
              name="Group"
              class="flex-fill flex-1 email-to-selector col-sm-4"
            >
              <v-select
                id="email-to-group-message"
                v-model="composeData.group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :close-on-select="false"
                :options="emailToGroupOptions"
                input-id="email-to-group"
                :loading="isLoading"
                :calculate-position="withPopper"
                append-to-body
                placeholder="Select a Group"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                <template #option="{ name, type }">
                  <b-row>
                    <b-col sm="12">
                      <span class="mr-1"> {{ name }}</span>
                      <b-badge
                        variant="secondary"
                      >
                        {{ type }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
            <feather-icon
              icon="UserIcon"
              size="20"
            />
            <validation-provider
              v-slot="validationContext"
              vid="email-to"
              name="User"
              :rules="{ required: isRequired }"
              class="flex-fill flex-grow-2 flex-2 email-to-selector"
            >
              <v-select
                id="email-to-user-message"
                v-model="composeData.user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :close-on-select="false"
                :options="emailToOptions"
                input-id="email-to-group"
                :loading="isLoading"
                :calculate-position="withPopper"
                append-to-body
                placeholder="Select a User"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                <template #option="{ avatar, name, role }">
                  <b-row>
                    <b-col sm="3">
                      <b-avatar
                        size="md"
                        :variant="avatar? 'light': 'secondary'"
                        :text="`${name.charAt(0)}`"
                        :src="avatar"
                      />
                    </b-col>
                    <b-col
                      sm="9"
                    >
                      <span class="mr-1 ml-n3"> {{ name }}</span>
                      <b-badge
                        variant="secondary"
                      >
                        {{ role }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :variant="avatar? 'light': 'secondary'"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </template>
          <template v-else-if="type === emailComposeTypes.SCHEDULED">
            <template>
              <feather-icon
                class="ml-50"
                icon="UsersIcon"
                size="20"
              />
              <validation-provider
                v-slot="validationContext"
                vid="email-to-group"
                :rules="{ required: isRequired }"
                name="Group"
                class="flex-fill flex-1 email-to-selector col-sm-4"
              >
                <v-select
                  v-if="composeData && composeData.group"
                  id="email-to-group"
                  v-model="composeData.group"
                  :class="
                    validationContext.errors && validationContext.errors.length ?
                      'form-control is-invalid' :
                      ''
                  "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :close-on-select="false"
                  :options="emailToGroupOptions"
                  input-id="email-to-group"
                  :loading="isLoading"
                  :calculate-position="withPopper"
                  append-to-body
                  placeholder="Select a Group"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  <template #option="{ name, type }">
                    <b-row>
                      <b-col sm="12">
                        <span class="mr-1"> {{ name }}</span>
                        <b-badge
                          variant="secondary"
                        >
                          {{ type }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    />
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
              <feather-icon
                v-if="hasProgramAdminRole"
                icon="UserIcon"
                size="20"
              />
              <validation-provider
                v-if="hasProgramAdminRole"
                v-slot="validationContext"
                vid="email-to"
                name="User"
                :rules="{ required: isRequired }"
                class="flex-fill flex-grow-2 flex-2 email-to-selector"
              >
                <v-select
                  id="email-to-user-message"
                  v-model="composeData.user"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :close-on-select="false"
                  :options="emailToOptions"
                  input-id="email-to-group"
                  :loading="isLoading"
                  :calculate-position="withPopper"
                  append-to-body
                  placeholder="Select a User"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  <template #option="{ avatar, name, role }">
                    <b-row>
                      <b-col sm="3">
                        <b-avatar
                          size="md"
                          :variant="avatar? 'light': 'secondary'"
                          :text="`${name.charAt(0)}`"
                          :src="avatar"
                        />
                      </b-col>
                      <b-col
                        sm="9"
                      >
                        <span class="mr-1 ml-n3"> {{ name }}</span>
                        <b-badge
                          variant="secondary"
                        >
                          {{ role }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :variant="avatar? 'light': 'secondary'"
                      :src="avatar"
                    />
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>
          </template>
          <template v-else-if="type === emailComposeTypes.INVITES">
            <validation-provider
              v-slot="validationContext"
              vid="email-invite"
              :rules="{ required: true, email_multi: true }"
              name="Email"
              class="w-100"
            >
              <b-form-input
                v-model="composeData.rawEmails"
                placeholder="Enter Email Address Here"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
            <span
              v-b-tooltip.hover.html.righttop="'Multiple emails should be separated by ;<br/> e.g., exam1@gmail.com;exam2@gmail.com'"
              class="validation-icon"
              variant="primary"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
              />
            </span>
          </template>
        </div>

        <!-- Field: Template -->
        <div
          v-if="type === emailComposeTypes.INVITES"
          class="compose-mail-form-field justify-content-start"
        >
          <label
            for="email-to"
            class="form-label"
          >
            Template:
          </label>
          <validation-provider
            v-slot="validationContext"
            vid="template-invite"
            :rules="{}"
            name="Template"
            class="w-100"
          >
            <div class="d-flex align-items-center">
              <v-select
                v-model="selectedTemplate"
                class="flex-fill flex-1 email-to-selector"
                :class="
                  validationContext.errors && validationContext.errors.length ?
                    'form-control is-invalid' :
                    'form-control'
                "
                label="name"
                :options="emailTemplateOptions"
                placeholder="Select Template"
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </div>
          </validation-provider>
        </div>
  
        <!-- Field: Cc (Hidden Initially) -->
        <div
          v-show="showCcField"
          class="compose-mail-form-field justify-content-start"
        >
          <label for="email-cc">CC: </label>
          <v-select
            v-model="composeData.cc"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            class="flex-grow-1 email-cc-selector"
            :close-on-select="false"
            :options="emailToOptions"
            input-id="email-cc"
          >
            <template #option="{ avatar, name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>

            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </div>
  
        <!-- Field: Bcc (Hidden Initially) -->
        <div
          v-show="showBccField"
          class="compose-mail-form-field justify-content-start"
        >
          <label for="email-bcc">Bcc </label>
          <v-select
            v-model="composeData.bcc"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            class="flex-grow-1 email-bcc-selector"
            :close-on-select="false"
            :options="emailToOptions"
            input-id="email-bcc"
          >
            <template #option="{ avatar, name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select>
        </div>
  
        <!-- Field: Subject -->
        <div class="compose-mail-form-field justify-content-start">
          <label for="email-subject">Subject: </label>
          <validation-provider
            v-slot="validationContext"
            ref="emailSubject"
            rules="required"
            name="Email Subject"
            class="w-100"
          >
            <b-form-input
              id="email-subject"
              v-model="composeData.subject"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>

        <div class="message-editor">
          <validation-provider
            v-slot="validationContext"
            ref="emailBody"
            rules="required"
            vid="emailBody"
            name="Email Body"
          >
            <aom-text-editor
              v-if="shallShowEmailComposeModal"
              v-model="composeData.body"
              :min-height="300"
              :state="
                getValidationState(
                  validationContext
                )
              "
              :type="type"
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
              class="p-50"
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BFormInvalidFeedback,
  BForm,
  BFormInput,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormCheckbox,
  VBTooltip
} from 'bootstrap-vue';
import ActionButton from "@/views/components/common/ActionButton.vue";

import Ripple from 'vue-ripple-directive';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import { usersService, groupsService, commsService }  from "@/services";
import AomTextEditor from '@aom-core/AoMTextEditor.vue';
import { createPopper } from '@popperjs/core';
import { TopicClass,TopicRecipient, topicChannel, topicTypes, recipientTypes } from '@models';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState,
  convertStringToDateIgnoreTimezone
} from "@/libs/utils";
//eslint-disable-next-line
import { required, validateMultipleEmails } from "@validations";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { userStatus, userRoles } from '@/models';
import { RolesClass } from '@models/userRolesClass';
import { emailComposeTypes } from '@/models/enums/emailComposeTypes';
import { groupTypeDisplay } from '@models';
import {
  actions,
  eventTriggers,
  eventTriggersDisplay,
  actionableEvent as defaultEvents,
  durationUnitsDisplay
} from '@models';
import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import moment from "moment";
import { isChampionFilterGroup, roleAlias, hasChampionOrProgramAdmin } from "@/@aom-core/utils/utils";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    ActionButton,
    BForm,
    BFormInput,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    AomTextEditor,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BFormGroup,
    AomEventsSelect,
    AomDatePicker,
    BFormCheckbox
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    type: {
      type: String,
      default: emailComposeTypes.MESSAGE
    },
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    isProgramCommunications: {
      type: Boolean,
      default: false
    },
    communication: {
      type: Object,
      default: null
    },
    isLibraryComms: {
      type: Boolean,
      default: false
    },
    isProgramTemplateComms: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingGroups: false,
      isLoadingTemplates: false,
      isUpdating: false,
      isSendingTestEmail: false,
      isScheduledSending: false,
      emailToOptions: [],
      emailToGroupOptions: [],
      emailTemplateOptions: [],
      emailPlugins: [
        'advlist', 'autolink','lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor',
        'searchreplace', 'visualblocks', 'code',
        'insertdatetime', 'media', 'table', 'paste', 'wordcount'],
      composeData:{
        name: undefined,
        user: [],
        group:[],
        rawEmails: undefined,
        subject: undefined,
        body: undefined,
        is_enabled: true
      },
      selectedUserRoles: [],

      eventTriggersDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      schedulable: {roles:[], scheduled_actions:[{ action_id: actions.SENDMAIL }]},

      selectedTemplate: null,
      emailComposeTypes,
    };
  },
  computed: {
    ...mapGetters("app", ["isAdminPortal"]),
    ...mapGetters("programs", ["defaultProgram"]),
    ...mapGetters("profile", ['profile', 'isProgramAdmin', 'isAdmin']),

    hasProgramAdminRole() {
      return this.isAdmin || this.isProgramAdmin(Number(this.$route.params.id));
    },
    submitButtonText() {
      if (this.type === this.emailComposeTypes.SCHEDULED) {
        return this.isUpdating ? "Saving" : "Save";
      }
      return this.isUpdating ? "Sending" : "Send";
    },
    sendTestText() {
      return this.isSendingTestEmail ? "Sending" : "Send a test to yourself";
    },
    eventsDisplayComputed () {
      if (this.containChampionOrProgramAdmin) {
        return this.eventsDisplayForChampion;
      }
      return this.eventsDisplay;
    },
    containChampionOrProgramAdmin() {
      return isChampionFilterGroup(this.composeData.group) || hasChampionOrProgramAdmin(this.composeData.user);
    },

    isRequired () {
      if(this.composeData.user.length > 0 || this.composeData.group.length > 0) {
        return false;
      };
      return true;
    },
    canSendNow() {
      if (this.communication) {
        const scheduledAction = this.communication.scheduled_action;
        return this.communication.is_enabled && this.communication.is_schedulable
          && (
            scheduledAction.actionable_event_id !== null 
            || 
            scheduledAction.absolute_date && moment().subtract(1, 'days') <= moment(scheduledAction.absolute_date) 
            && moment().add(1, 'days') >= moment(scheduledAction.absolute_date)
          );
      }
      return false;
    },
    modalTitle() {
      const titles = {
        "champion-program-invites": "Invite users to the program",
        "champion-program-schedules": "Compose a scheduled email"
      };
      return titles[this.$route.name] ?? "Compose Mail";
    }
  },
  watch: {
    'composeData.group' : {
      handler: function(groups) {
        if (groups.length) {
          let isChampionGroup = isChampionFilterGroup(groups);
          if (isChampionGroup) {
            this.hideParticipantAction();
          }
        }
      },
      deep: true,
      immediate: true
    },
    'composeData.user' : {
      handler: function(users) {
        if (users.length) {
          let hasChampion= hasChampionOrProgramAdmin(users);
          if (hasChampion) {
            this.hideParticipantAction();
          }
        }
      },
      deep: true,
      immediate: true
    },
    happenWhen() {
      this.schedulable = {roles:[], scheduled_actions:[{ action_id: actions.SENDMAIL }]};
    },
    actionableEvent(n) {
      this.updateScheduledEvent(n);
    },
    duration(n) {
      this.updateScheduledDuration(n);
    },
    period(n) {
      this.updateScheduledPeriod(n);
    },
    scheduledDate(n) {
      this.updateScheduledAbsoluteDate(n);
    },
    communication: {
      handler(n) {
        if (n) {
          this.setItem(n);
        }
      },
      immediate: true,
      deep: true
    },
    shallShowEmailComposeModal(n) {
      if (!n) {
        this.resetForm();
        this.$emit('closeComposeModal');
      }
    },
    selectedTemplate: {
      handler(n) {
        if (!n) {
          this.composeData.body = undefined;
          this.composeData.subject = undefined;
          return;
        }
        this.composeData.body = n.body;
        this.composeData.subject = n.subject;
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.isProgramCommunications && (this.type === this.emailComposeTypes.MESSAGE || this.type === this.emailComposeTypes.SCHEDULED)) {
      this.getProgramParticipantsList();
      this.getProgramGroups();
    }
    if (this.type === this.emailComposeTypes.INVITES) {
      this.getProgramInviteTemplates();
    }
    if (this.isProgramTemplateComms) {
      this.getProgramGroups();
    }
  },
  methods: {
    hideParticipantAction() {
      if (this.happenWhen?.id !== eventTriggers.ON) {
        this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
      }
      
      if (this.schedulable?.scheduled_actions[0]?.actionable_event_id) {
        let isKeyDate = this.eventsDisplay.find(event => event.id === this.schedulable?.scheduled_actions[0]?.actionable_event_id)?.isKeyDate;
        if (!isKeyDate) {
          this.actionableEvent = undefined;
        }
      }
    },
    async sendNow() {
      const h = this.$createElement;
      const message = h('div', {}, [
        h('p', 'Are you sure you want to send this scheduled message now?'),        
        h('p', this.communication.messages[0].subject + ' ' + this.communication.name),        
      ]);
      this.$bvModal
        .msgBoxConfirm([message], {
          title: 'Send Scheduled Message Now',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.isScheduledSending = true;
              const programId = this.$route.params.id;
              const result = await commsService.runScheduledNow(programId, this.communication.id);
              if (result) {
                this.$toast(makeSuccessToast('Scheduled communication was sent successfully.'));
              }
              this.isScheduledSending = false;
            } catch (e) {
              this.isScheduledSending = false;
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
    preSelectUserEmails() {
      const userIds = this.$route.query.user_ids;
      if(Array.isArray(userIds) && userIds.length > 0) {
        const users = this.emailToOptions.filter(e => userIds.includes(e.id));
        if(users.length > 0) {
          this.composeData.user = [...users];
        }
      } else if(userIds) {
        const users = this.emailToOptions.filter(e => e.id === userIds);
        if(users.length > 0) {
          this.composeData.user = [...users];
        }
      }
    },
    async getProgramParticipantsList() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await usersService.getListParticipantsByProgram(programId, null, false, true, true);
        const { items }  = response.data;
        const removeInActiveUsers = items.filter(i => i.user_roles.some(u => u.status_id === userStatus.ACTIVE));
        this.emailToOptions = removeInActiveUsers.map(i => ({
          id: i.id, 
          email: i.email,
          avatar: i.profile_image?.url || "",
          name: i.full_name, 
          role: i.user_roles.filter(r => [userRoles.PARTICIPANT_CANDIDATE, userRoles.CHAMPION_CANDIDATE].indexOf(r.role_id) === -1).map(r => roleAlias(r.role.id, this.defaultProgram, false, true) ).join(", "),
          user_roles: i.user_roles
        }));
        if(this.$route.query.user_ids) {
          this.preSelectUserEmails();
        }
      } catch (e) {
        this.$toast(makeErrorToast("Participants email list not loaded."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplateComms) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getProgramGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.emailToGroupOptions = items.map(i => ({id: i.id, name: i.name, type: i.type.name, search_expr: i.search_expr}));
      } catch (e) {
        this.$toast(makeErrorToast("Groups list not loaded."));
        console.log(e);
      } finally {
        this.isLoadingGroups = false;
      }
    },
    async getProgramInviteTemplates() {
      try {
        this.isLoadingTemplates = true;
        const programId = this.$route.params.id;
        const response = await commsService.getProgramInviteTemplatesList(programId);
        const {items} = response.data;
        this.emailTemplateOptions = items.map(i => ({id: i.id, name: i.name, subject: i.subject, body: i.body}));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Templates list not loaded."));
      } finally {
        this.isLoadingTemplates = false;
      }
    },

    sendEmail() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isUpdating = true;
          if (this.isProgramCommunications) {
            const programId = this.$route.params.id;
            if (this.communication) {
              const communicationId = this.communication.id;
              const message = this.getEmailMessage();
              await commsService.updateProgramsComms(programId, communicationId, message);
              this.$emit('update:shall-show-email-compose-modal', false);
              this.$emit('emailAdded');
            } else {
              const message = this.getEmailMessage();
              await commsService.postCreateTopic(programId, message);
              this.$emit('update:shall-show-email-compose-modal', false);
              this.$emit('emailAdded', message);
            }
          } else {
            const payload = {
              name: this.composeData.name,
              subject: this.composeData.subject,
              body: this.composeData.body,
              scheduled_actions: this.schedulable.scheduled_actions,
              channel_id: topicChannel.EMAIL,
              recipients: [],
              type_id: topicTypes.MESSAGE,
            };
            if (!this.isLibraryComms && !this.isProgramTemplateComms) {
              payload.recipients = [{
                recipient_id: this.composeData.group[0].id,
                recipient_type: recipientTypes.GROUPS,
              }];
              const response = await commsService.updateProgramsComms(this.$route.params.id, this.communication.id, payload);
              if (response) {
                this.$emit('emailAdded');
              }
            }
            else {
              if (this.communication) {
                const response = await commsService.updateCommsScheduled(this.communication.id, payload);
                if (response) {
                  this.$emit('emailAdded');
                }
              } else {
                const response = await commsService.postCommsScheduled(payload);
                if (response) {
                  this.$emit('emailAdded');
                }
              }
            }
          }
        } catch(e) {
          this.$toast(makeErrorToast("Email not sent please try again."));
          console.log(e);
        } finally {
          this.isUpdating = false;
        }
      });
    },
    testSendEmail() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isSendingTestEmail = true;
          const payload = {
            name: this.composeData.name,
            subject: this.composeData.subject,
            body: this.composeData.body,
            channel_id: topicChannel.EMAIL,
            type_id: topicTypes.TEST_MESSAGE,
          };
          const response = this.isProgramCommunications? 
            await commsService.postProgramCommsTestSend(this.$route.params.id, payload): 
            await commsService.postCommsTestSend(payload);
          if (response) {
            this.$emit('emailSent');
          }
        } catch(e) {
          this.$toast(makeErrorToast("There was a problem sending the test email."));
          console.log(e);
        } finally {
          this.isSendingTestEmail = false;
        }
      });
    },
    getEmailMessage() {
      let message = new TopicClass({
        subject: this.composeData.subject,
        body: this.composeData.body,
        channel_id: topicChannel.EMAIL,
        type_id: topicTypes.MESSAGE,
        recipients: [
          ...this.composeData.user.map(u => new TopicRecipient(
            {
              recipient_id: u.id,
              recipient_type: recipientTypes.USER,
              raw_email_address: u.email
            }) 
          ), 
          ...this.composeData.group.map(g => new TopicRecipient(
            {
              recipient_id: g.id,
              recipient_type: recipientTypes.GROUPS,
            })
          )
        ],
      });
      if (this.type === this.emailComposeTypes.SCHEDULED) {
        message.name = this.composeData.name; // Name (Internal)
        message.is_enabled = this.composeData.is_enabled;
        message.is_schedulable = true;
        message.scheduled_actions = this.schedulable.scheduled_actions;
        if (message.scheduled_actions[0].actionable_event_id) {
          delete message.scheduled_actions[0].absolute_date;
        } else {
          delete message.scheduled_actions[0].actionable_event_id;
        }
        if (!message.scheduled_actions[0].relative_value) {
          delete message.scheduled_actions[0].relative_unit_id;
          delete message.scheduled_actions[0].relative_value;
        }
      }
      if (this.type === this.emailComposeTypes.INVITES) {
        message.type_id = topicTypes.INVITE,
        message.recipients = this.composeData.rawEmails.split(';').map(email => ({
          raw_email_address: email
        }));
        if (this.selectedTemplate && this.selectedTemplate.id) {
          message.message_template_id = this.selectedTemplate.id;
        }
      }
      return message;
    },
    withPopper(dropdownList, component) {
      if (component.$attrs.id == "email-to-group-message") {
        dropdownList.classList.add("email-to-group-message");
      } else if (component.$attrs.id == "email-to-user-message") {
        dropdownList.classList.add("email-to-user-message");
      }
      dropdownList.style.maxWidth = "550px";
      dropdownList.style.minWidth = "380px";
      dropdownList.style.maxHeight = "300px";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    updateUserRole(role) {
      this.selectedUserRoles = role;
      if(role.length > 0) {
        const targetRoles = role.map(r => new RolesClass({ id: r.id, name: r.name }));
        this.schedulable = {
          ...this.schedulable,
          roles: targetRoles
        };
      }
    },
    updateScheduledEvent(e) {
      if(e) {
        const {id} = e;
        if(id) {
          if(this.schedulable.scheduled_actions.length > 0) {
            const update = this.schedulable.scheduled_actions.map(action => ({...action, actionable_event_id: id, absolute_date: null}));
            this.schedulable = {
              ...this.schedulable,
              scheduled_actions: update
            };
          } else {
            this.schedulable = {
              ...this.schedulable,
              scheduled_actions: [{
                actionable_event_id: id
              }]
            };
          }
        }
      }
    },
    updateScheduledDuration(d) {
      if(d) {
        const {id} = d;
        if(id) {
          if(this.schedulable.scheduled_actions.length > 0) {
            const update = this.schedulable.scheduled_actions.map(action => ({...action, relative_unit_id: id}));
            this.schedulable = {
              ...this.schedulable,
              scheduled_actions: update
            };
          } else {
            this.schedulable = {
              ...this.schedulable,
              scheduled_actions: [{
                relative_unit_id: id
              }]
            };
          }
        }
      }
    },
    updateScheduledPeriod(p) {
      if(p) {
        let newP = 0;
        // Convert relative_value
        if (this.happenWhen) {
          switch (this.happenWhen.id) {
            case eventTriggers.BEFORE:
              newP = -p;
              break;
            case eventTriggers.ON:
              newP = 0;
              break;
            case eventTriggers.AFTER:
              newP = p;
              break;
          }
        }

        if(this.schedulable.scheduled_actions.length > 0) {
          const update = this.schedulable.scheduled_actions.map(action => ({...action, relative_value: newP}));
          this.schedulable = {
            ...this.schedulable,
            scheduled_actions: update
          };
        } else {
          this.schedulable = {
            ...this.schedulable,
            scheduled_actions: [{
              relative_value: newP
            }]
          };
        }
      }
    },
    updateScheduledAbsoluteDate(date) {
      if(date) {
        if(this.schedulable.scheduled_actions.length > 0) {
          const update = this.schedulable.scheduled_actions.map(action => ({...action, actionable_event_id: null, absolute_date: date}));
          this.schedulable = {
            ...this.schedulable,
            scheduled_actions: update
          };
        } else {
          this.schedulable = {
            ...this.schedulable,
            scheduled_actions: [{
              absolute_date: date
            }]
          };
        }
      }
    },
    setItem(comm) {
      if (comm) {
        this.composeData.name = comm.name;
        this.composeData.body = comm.messages[0].body;
        this.composeData.subject = comm.messages[0].subject;
        this.composeData.is_enabled = comm.is_enabled ?? true;
        this.composeData.group = comm.topic_recipients.filter(topicRecipient => topicRecipient.recipient_type === recipientTypes.GROUPS).map(r => ({
          id: r.recipient?.id,
          name: r.recipient?.name,
          type: groupTypeDisplay[r.recipient?.type_id],
          search_expr: r.recipient?.search_expr
        }));
        this.composeData.user = comm.topic_recipients.filter(topicRecipient => topicRecipient.recipient_type === recipientTypes.USER).map(r => ({
          id: r.recipient?.id,
          name: r.recipient?.full_name,
          type: groupTypeDisplay[r.recipient?.type_id],
          search_expr: r.recipient?.search_expr,
          user_roles: r.recipient?.user_roles
        }));

        // Bind scheduled action
        if (comm.scheduled_action) {
          const scheduledAction = comm.scheduled_action;

          if (!scheduledAction.relative_value) {
            this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
          } else {
            if (scheduledAction.relative_value < 0) {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.BEFORE);
            } else {
              this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.AFTER);
            }
          }

          this.$nextTick(() => {
            this.actionableEvent = scheduledAction.absolute_date
              ? { id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }
              : this.eventsDisplay.filter(a => a.id === scheduledAction.actionable_event_id).shift();
            this.period = Math.abs(scheduledAction.relative_value);
            this.duration = durationUnitsDisplay.filter(d => d.id === scheduledAction.relative_unit_id)[0];
            this.scheduledDate = scheduledAction.absolute_date ? convertStringToDateIgnoreTimezone(scheduledAction.absolute_date) : undefined;
          });
        }

        this.schedulable.roles = comm.topic_recipients.map(r => ({
          id: r.recipient.id,
          name: r.recipient.name
        }));
      }
    },
    resetForm() {
      this.$refs.observer.reset();
      this.composeData = {
        user: [],
        group:[],
        rawEmails: undefined,
        subject: undefined,
        body: undefined,
        is_enabled: true
      };
      this.happenWhen = undefined;
      this.actionableEvent = undefined;
      this.period = undefined;
      this.duration = undefined;
      this.scheduledDate = undefined;
      this.selectedTemplate = null;
    },
  },
  setup(props, { emit }) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      eventsDisplayForChampion,
      minCustomDate
    } = useActionableEvent(props.isProgramCommunications);
    const showCcField = ref(false);
    const showBccField = ref(false);
  
    const discardEmail = () => {
      emit('update:shall-show-email-compose-modal', false);
    };
  
    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      eventsDisplayForChampion,
      minCustomDate,

      showCcField,
      showBccField,
      discardEmail,
      getValidationState
    };
  },
};
</script>
  
<style lang="scss">
  @import '@/assets/scss/vue/libs/vue-select.scss';
  @import '@/assets/scss/vue/libs/quill.scss';

  [data-popper-placement='bottom'] {
    border-radius: 4px 4px 0 0;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
    z-index: 9999;
  }
  .v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
    z-index: 9999;
  }
  .tooltip {
    .tooltip-inner {
      text-align: left;
      max-width: 280px !important;
    }
  }
  .email-to-group-message {
    left: 100px !important;
  }
  .email-to-user-message {
    left: -100px !important;
  }
</style>
  
<style lang="scss" scoped>
  form ::v-deep {

    // Mail To vue-select style
    .v-select {
      .vs__dropdown-toggle {
        border: 0;
        box-shadow: none;
      }
      .vs__open-indicator {
        display: none;
      }
    }

    // Quill Editor Style
    .quill-editor {
      .ql-container.ql-snow {
        border-bottom: 0 !important;
      }
    }
  }
</style>