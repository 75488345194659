<template>
  <b-modal
    id="modal-add-training"
    v-model="showModal"
    title="Add Training"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <b-form>
        <!-- Add training -->
        <v-select
          v-model="selectedCourses"
          class="mt-1 mb-2"
          multiple
          :dir="dir"
          placeholder="Select Courses"
          label="name"
          :options="options"
          :loading="isLoading"
          transition="fade"
          @search="debounceInput"
        />
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BForm, BModal } from "bootstrap-vue";
import vSelect from "vue-select";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import trainingsService from "@/services/trainingsService";

export default {
  components: {
    BCard,
    BForm,
    BModal,
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      keyword: null,
      selectedCourses: [],
      options: [],
      dir: "ltr",
      isLoading: false,
      isSubmitting: false,
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selectedCourses = [];
    },
    async onSubmit() {
      this.isSubmitting = true;
      try {
        await trainingsService.addTrainingToProgram(this.$route.params.id, {
          trainings: this.selectedCourses.map(course => ({ id: course.id })),
        });
        this.$toast(makeSuccessToast("Training was successfully added"));
        this.$emit("trainingAdded");
        this.$emit("hiddenModal", false);
        this.clearForm();
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.isLoading = true;
      try {
        const response = await trainingsService.getLibraryTrainings({
          columnFilters: this.keyword
            ? [
              {
                field: "course_name",
                value: this.keyword,
              },
            ]
            : [],
        });
        this.options = response.data.items.map(course => ({
          id: course.id,
          name: course.course_name,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
